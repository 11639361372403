import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트 로밍 내 현지망 라인업 확대… 베트남에 이어 일본도 오픈",
  "| 현지와 직접 제휴 통해 일본 1등 통신사 NTT도코모 회선 사용… 안정적인 데이터 로밍 제공",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/44/44_img1.png" style={{ width: "300px", margin: "auto" }} alt="" />
      </div>
      <div className="desc">&lt;(위) 핀다이렉트 일본 현지망 로밍&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)가 17일 핀다이렉트 로밍 내 현지망 상품을 베트남에 이어 일본까지 확대 런칭한다고 밝혔다.
      <br />
      <br />
      최근, 해외여행 수요가 늘어나며 로밍에 대한 관심도 뜨거워지고 있다. 국가별 비중으로 보면 전체 해외 여행객의 절반 이상이
      여행지로 일본을 선택했을 만큼 일본여행에 대한 수요가 높다. (출처 : 야놀자, ‘2023년 추석연휴 트렌드 발표’)
      <br />
      <br />
      스테이지파이브는 이러한 시장 환경을 고려, 안정적이고 빠른 일본 현지망 상품을 도입하게 되었다. 특히, 해당 상품은 현지와의
      직접 제휴를 통해 일본 내 최대 가입자수를 보유한 통신사 NTT도코모 회선을 사용할 수 있게 된 것이 특징이다. 제휴 형태는 양사
      전산을 직접 연동하는 방식이다. 이는 현지 유심을 선불 구매하는 형태의 단순한 제휴와 차별화된 부분으로, 스테이지파이브가 국내
      유일하게 도입하였다. 해당 방식을 통하면 현지 통신사와 스테이지파이브의 전산을 직접 연동할 수 있게 된다. 따라서 해외에서의
      데이터 사용량 실시간 조회, 데이터 충전이나 연장도 핀다이렉트 앱 내 마이페이지를 통해 즉시 처리할 수 있다. 안정적인 서비스
      제공을 위해 수개월에 걸친 일본 내 테스트 과정도 거쳤다.
      <br />
      <br />
      사용 중, 혹은 사용 전후로 궁금한 점이 있거나 불편한 점이 있는 고객은 핀다이렉트만의 24시간 로밍 전문 상담을 통해 해결이
      가능하다.
      <br />
      <br />
      가격 또한 합리적으로 형성되어 있다. 핀다이렉트 로밍패스 적용시 일본 500MB 기준 1,900 원부터 시작한다. 따라서 일반 통신사
      로밍 대비 압도적으로 저렴한 가격에 현지망 상품을 선택할 수 있다.
      <br />
      <br />
      스테이지파이브 관계자는 “더욱 많은 분들이 합리적인 가격, 편리한 UX로 선보이는 핀다이렉트만의 로밍 서비스를 체험해 보셨으면
      좋겠다”며, “비싸고, 불편하고, 복잡한 기존 로밍은 핀다이렉트 로밍을 만나면 모두 잊어버리게 될 것”이라고 밝혔다.
      <br />
      <br />
      한편, 핀다이렉트 로밍 상품은 지난 22년 12월 해외여행 1등 플랫폼 인터파크, 여행 앱 사용자 1위(700만명)인 트리플과 함께 그랜드
      오픈하여 업계 이목을 끌은 바 있다.
      <br />
    </p>
  </div>
)

const press44 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 11월 16일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 핀다이렉트 로밍 內 현지망 확대… ‘이젠 일본여행도 현지망으로!’ "
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press44
